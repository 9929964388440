import * as Sentry from '@sentry/nextjs'

import { adseConfig } from '~lib/config'

import type { JagexAnalyticsEvent } from './types'

const createBeaconBlob = (analyticsEvent: JagexAnalyticsEvent) => {
  /*
   * NOTE: the type has to be text/plain to prevent the following:
   * Cross-Origin Read Blocking (CORB) blocked cross-origin response
   * https://kite-web.development.data.aws.jagex.com/api/page_view with MIME type application/json.
   * See https://www.chromestatus.com/feature/5629709824032768 for more details.
   */
  return new Blob([JSON.stringify([analyticsEvent])], { type: 'text/plain' })
}

export const sendPageView = (sid: string, url?: string) => {
  const pageFields = url ? { s: url } : {}
  // don't throw the error to allow the application to work with tracking not working
  try {
    if (adseConfig.enabled) {
      const blob = createBeaconBlob({ ...getBaseJagexAnalyticsEventData(sid), ...pageFields })

      const apiUrl = new URL('/api/page_view', adseConfig.baseUrl)
      navigator.sendBeacon(apiUrl.href, blob)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const sendClickEvent = ({
  id,
  action,
  category,
  destination,
  sid,
}: {
  id: string
  action: string
  category: string
  destination?: string
  sid: string
}) => {
  const clickEventData: JagexAnalyticsEvent = {
    ...getBaseJagexAnalyticsEventData(sid),
    e: {
      id,
      a: action,
      c: category,
      d: destination,
    },
  }

  // don't throw the error to allow for links to work if tracking fails
  try {
    if (adseConfig.enabled) {
      const blob = createBeaconBlob(clickEventData)
      const apiUrl = new URL('/api/click_event', adseConfig.baseUrl)
      navigator.sendBeacon(apiUrl.href, blob)
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const getBaseJagexAnalyticsEventData = (sid: string): JagexAnalyticsEvent => {
  return {
    _sv: 1,
    d: window.location.hostname,
    s: window.location.pathname,
    l: 'en-GB',
    pt: window.document.title,
    aid: undefined,
    sid,
    m: {
      t: new Date().toISOString(),
    },
    c: {
      sr: `${window.screen.width}x${window.screen.height}`,
      vs: `${window.innerWidth}x${window.innerHeight}`,
    },
  }
}
