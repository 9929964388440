/**
 * Site-wide application config
 */

const adseConfig = {
  enabled: process.env.NEXT_PUBLIC_JAGEX_ANALYTICS_ENABLED === 'true' ?? false,
  baseUrl: process.env.NEXT_PUBLIC_JAGEX_ANALYTICS_BASE_URL ?? '',
  cookieName: 'ja_sid',
  cookieDomain: process.env.NEXT_PUBLIC_JAGEX_ANALYTICS_COOKIE_DOMAIN ?? '',
  expiryHours: process.env.NEXT_PUBLIC_JAGEX_ANALYTICS_COOKIE_EXPIRY_HOURS
    ? Number.parseInt(process.env.NEXT_PUBLIC_JAGEX_ANALYTICS_COOKIE_EXPIRY_HOURS, 10)
    : 3,
  cookieSecure: process.env.NODE_ENV !== 'development',
} as const

const fingerprintConfig = {
  cookieName: 'f_id',
  cookieDomain: process.env.NEXT_PUBLIC_FINGERPRINT_COOKIE_DOMAIN ?? '',
  expiryDays: process.env.NEXT_PUBLIC_FINGERPRINT_COOKIE_EXPIRY_DAYS
    ? Number.parseInt(process.env.NEXT_PUBLIC_FINGERPRINT_COOKIE_EXPIRY_DAYS, 10)
    : 365,
  cookieSecure: process.env.NODE_ENV !== 'development',
} as const

const gtmConfig = {
  id: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? '',
  auth: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH,
  env: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
} as const

const cookiebotConfig = {
  id: process.env.NEXT_PUBLIC_COOKIEBOT_ID ?? '',
} as const

export { adseConfig, cookiebotConfig, fingerprintConfig, gtmConfig }
