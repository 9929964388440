
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { CookieConsent } from '@jagex-pp/cookie-consent/react'
import { usePageViewTracking } from '@jagex-pp/gtm/nextjs'
import { HideMarketingPixel } from '@jagex-pp/misc-helpers/react'
import { ThemeProvider } from '@jagex-pp/rs-franchise-design'
import { SessionIdsProvider } from '@jagex-pp/session-ids'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import React, { useEffect } from 'react'

import { cookiebotConfig } from '~lib/config'
import { useGoogleOptimize } from '~lib/go'
import { JagexAnalytics } from '~lib/jagex/analytics/hook'
import { SentryUser } from '~lib/sentry'
import { PersistSessionIds, SESSION_IDS_CONFIG } from '~lib/sessionIds'

function Website({ Component, pageProps }: AppProps): JSX.Element {
  usePageViewTracking()
  useGoogleOptimize()

  useEffect(() => {
    async function loadAxe() {
      const [ReactDOM, axe] = await Promise.all([import('react-dom'), import('@axe-core/react')])

      axe.default(React, ReactDOM, 1000, {
        rules: [
          {
            id: 'color-contrast',
            selector: ':not([data-axe-color-contrast]="disabled")',
          },
        ],
      })
    }
    if (process.env.NODE_ENV !== 'production') {
      loadAxe()
    }
  }, [])

  return (
    <SessionIdsProvider ids={SESSION_IDS_CONFIG}>
      <ThemeProvider>
        <HideMarketingPixel wrapper={Head} />
        <CookieConsent cookiebotId={cookiebotConfig.id} />
        <PersistSessionIds />
        <JagexAnalytics />
        <SentryUser />
        <Component {...pageProps} />
      </ThemeProvider>
    </SessionIdsProvider>
  )
}

const __Page_Next_Translate__ = Website


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  