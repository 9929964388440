/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/nextjs'
import React, { useEffect } from 'react'

import { useFingerPrintId } from '~lib/sessionIds'

/**
 * Hook to update the Sentry client's user id from the SessionsId context
 */
export const useSentryUser = () => {
  const fingerprintId = useFingerPrintId()

  useEffect(() => {
    Sentry.setUser({ id: fingerprintId })
  }, [fingerprintId])
}

/**
 * The useSentryUser hook in a component, for ease of use in a JSX tree
 */
export const SentryUser: React.FC = () => {
  useSentryUser()

  return null
}
