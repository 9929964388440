/**
 * Checks if the html element is an anchor
 *
 * @param el html element to check
 * @returns true if the element is an anchor
 */
export const isElementAnchor = (el: HTMLElement | null): el is HTMLAnchorElement =>
  el?.tagName === 'A' && 'href' in el

/**
 * Checks if the html element is a button
 *
 * @param el html element to check
 * @returns true if the element is an image
 */
export const isElementButton = (el: HTMLElement | null): el is HTMLButtonElement =>
  el?.tagName === 'BUTTON'

/**
 * Finds the anchor by going up the DOM tree
 * @param element the element to start looking upwards from
 * @returns the anchor element if found
 */
export function findAnchor(element: HTMLElement | null): HTMLAnchorElement | null {
  if (isElementAnchor(element)) {
    return element
  }
  if (element?.parentElement) {
    return findAnchor(element.parentElement)
  }
  return null
}

/**
 * Finds the button by going up the DOM tree
 * @param element the element to start looking upwards from
 * @returns the button element if found
 */
export function findButton(element: HTMLElement | null): HTMLButtonElement | null {
  if (isElementButton(element)) {
    return element
  }
  if (element?.parentElement) {
    return findButton(element.parentElement)
  }
  return null
}

/**
 * Opens a link in a new tab
 * @param url url to pen in a new tab
 */
export function openInNewTab(url: string) {
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  window.open(url, '_blank')?.focus()
}
