import { useSessionIdsContext } from '@jagex-pp/session-ids'
import useEventListener from '@use-it/event-listener'

import { adseConfig, fingerprintConfig } from '~lib/config'

export const SESSION_IDS_CONFIG = [
  {
    cookieName: adseConfig.cookieName,
    expiry: adseConfig.expiryHours,
    expiryUnit: 'hour',
    domain: adseConfig.cookieDomain,
    secure: adseConfig.cookieSecure,
  },
  {
    cookieName: fingerprintConfig.cookieName,
    expiry: fingerprintConfig.expiryDays,
    expiryUnit: 'day',
    domain: fingerprintConfig.cookieDomain,
    secure: adseConfig.cookieSecure,
  },
]

export const PersistSessionIds: React.FC = () => {
  const { persistAllSessionIds } = useSessionIdsContext()

  useEventListener('CookiebotOnAccept', () => {
    if (!window.Cookiebot?.consent.statistics) {
      return
    }
    persistAllSessionIds()
  })

  return null
}

export const useAdseId = (): string => {
  const { getSessionIdValueByName } = useSessionIdsContext()
  return getSessionIdValueByName(adseConfig.cookieName) as string
}

export const useFingerPrintId = (): string => {
  const { getSessionIdValueByName } = useSessionIdsContext()
  return getSessionIdValueByName(fingerprintConfig.cookieName) as string
}
