import {
  analyticsActionProperty,
  analyticsCategoryProperty,
  analyticsIdProperty,
} from '@jagex-pp/rs-franchise-design'
import * as Bowser from 'bowser'
import type { NextRouter } from 'next/router'

import { adseConfig } from '~lib/config'

import { sendClickEvent } from './api'
import { findAnchor, findButton, openInNewTab } from './domUtils'

const ANALYTICS_ALLOW = ['runescape.com', 'jagex.com']

const handleButtonClickAnalytics = async (
  button: HTMLButtonElement,
  event: MouseEvent,
  sid: string,
) => {
  if (button) {
    if (button.type !== 'submit') {
      event.preventDefault()
    }

    await sendClickEvent({
      id: button.getAttribute(analyticsIdProperty)!,
      action: button.getAttribute(analyticsActionProperty)!,
      category: button.getAttribute(analyticsCategoryProperty)!,
      sid,
    })

    button.onclick?.(event)
  }
}

export const appendAnalyticsHash = (href: string, sid: string): string => {
  const url = new URL(href)

  if (ANALYTICS_ALLOW.some((domain) => url.hostname.endsWith(domain))) {
    // assumes we don't have existing hashes in links. Would need to define that what to do in those cases
    url.hash = `${adseConfig.cookieName}=${sid}`
  }
  return url.href
}

const checkIsOpenInNewTabSupported = (): boolean => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const os = browser.getOS()
  const isIos = os?.name === 'iOS'
  const isSafari = browser.getBrowserName() === 'Safari'

  return !isSafari && !isIos
}

const createAnchorAnalyticsPayload = (a: HTMLAnchorElement, sid: string) => ({
  id: a.getAttribute(analyticsIdProperty)!,
  action: a.getAttribute(analyticsActionProperty)!,
  category: a.getAttribute(analyticsCategoryProperty)!,
  destination: a.href,
  sid,
})

export async function onClickCallback(e: MouseEvent, router: NextRouter, sid: string) {
  // Handle button click
  const button = findButton(e.target as HTMLElement)
  if (button) {
    await handleButtonClickAnalytics(button, e, sid)
    return
  }

  const a = findAnchor(e.target as HTMLElement | null)
  // If clicked on any part of the page that isn't a link, and ignore links provided by plugins (like Cookiebot)
  if (a === null || a.href.slice(-1) === '#' || a.href === '') {
    return
  }

  // don't follow link before sending the tracking
  e.preventDefault()

  const payload = createAnchorAnalyticsPayload(a, sid)

  // ignore link behaviour with ignore link attribute (the behaviour is overwritten in some way programmatically) but send analytics
  if (a.getAttribute('data-ja-ignore-link') === 'true') {
    sendClickEvent(payload) // Wait for the event to be captured by Jagex Analytics before navigating
  }
  // open new tab when needed
  else if (a.target === '_blank' && checkIsOpenInNewTabSupported()) {
    sendClickEvent(payload) // Wait for the event to be captured by Jagex Analytics before navigating

    openInNewTab(appendAnalyticsHash(a.href, sid))
  }
  // handle next links with router
  else if (a.hasAttribute('data-next-link')) {
    sendClickEvent(payload) // Fire and forget because the app is mounted no need to await

    const url = new URL(a.href)
    const target = url.pathname + url.search + url.hash
    router.push(target)
  } else {
    sendClickEvent(payload) // Wait for the event to be captured by Jagex Analytics before navigating

    // navigate to the link
    window.location.assign(appendAnalyticsHash(a.href, sid))
  }
}
