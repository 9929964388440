import useEventListener from '@use-it/event-listener'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { useAdseId } from '~lib/sessionIds'

import { sendPageView } from './api'
import { onClickCallback } from './core'

/**
 * Hook to send page views and listen for button and link clicks
 */
export const useJagexAnalytics = () => {
  const router = useRouter()
  const adseId = useAdseId()

  useEffect(() => {
    // send initial page view
    sendPageView(adseId)
  }, [adseId])

  // attach a global click listener for button and link clicks
  useEventListener(
    'click',
    (e: MouseEvent) => {
      onClickCallback(e, router, adseId)
    },
    global as unknown as Window,
  )

  // listen for route changes and send page view events
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      sendPageView(adseId, url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, adseId])
}

/**
 * The useJagexAnalytics hook in a component, for ease of use in a JSX tree
 */
export const JagexAnalytics: React.FC = () => {
  useJagexAnalytics()

  return null
}
