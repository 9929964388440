/* eslint-disable import/prefer-default-export */

import { useEffect } from 'react'

/**
 * Basic MVP to fire a custom event.
 * This will certainly need to be expanded once we launch more pages -
 * for example, hooked into router page loads etc.
 */
function useGoogleOptimize() {
  useEffect(() => {
    window.dataLayer?.push({ event: 'optimize.activate' })
  }, [])
}

export { useGoogleOptimize }
